import { Col, Modal, Row, Space, Typography } from "antd"
import { FunctionComponent } from "react"
import { AssetImage } from "../../assets/image/Index"

interface IProps {
    open: boolean
    onClosed: () => void
}

export const 전문가Modal: FunctionComponent<IProps> = (props) => {
    const { open, onClosed } = props

    const contents = [
        AssetImage.banner.전문가.변리사,
        AssetImage.banner.전문가.가능성,
        AssetImage.banner.전문가.자문,
        AssetImage.banner.전문가.범위설정,
        AssetImage.banner.전문가.출원,
    ]

    return (
        <Modal
            // title="이용약관동의"
            open={open}
            destroyOnClose={true}
            onCancel={() => { onClosed() }}
            footer={null}
        >
            <Row>
                <Col span={24}>
                    <Typography.Text
                        style={{
                            fontSize: 24,
                        }}
                    >
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 24,
                            }}
                        >
                            {`전문가 매칭`}
                        </Typography.Text>
                        {`을 하게 되면`}
                    </Typography.Text>
                </Col>
                <Col span={24}>
                    <Typography.Text
                        strong
                        style={{
                            fontSize: 24,
                        }}
                    >
                        {`이런점이 좋아요!`}
                    </Typography.Text>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Space size={30} direction="vertical" style={{ paddingTop: 40, }}>
                        {contents.map((c) => {
                            return (
                                <img
                                    key={c}
                                    src={c}
                                    width={'100%'}
                                    alt={'banner'}
                                />
                            )
                        })}
                    </Space>
                </Col>
            </Row>
            {/* <Row>
                {contents.map((c) => {
                    const isActivated = ids.includes(c.id)
                    return (
                        <Col key={c.id} span={24}>
                            <Row justify={'space-between'}>
                                <Col>
                                    <Typography.Link
                                        onClick={() => {
                                            if(isActivated) {
                                                setIds(ids.filter( i => i !== c.id))
                                            } else {
                                                setIds([ ...ids, c.id ])
                                            }
                                        }}
                                    >
                                        <Space>
                                            <img src={isActivated ? Vector.check.blue : Vector.check.gray} />
                                            <Typography.Text style={{ fontSize: 16, color: Color.Gray80 }}>
                                                {c.title}
                                            </Typography.Text>
                                        </Space>
                                    </Typography.Link>
                                </Col>
                                {c.url && (
                                    <Col>
                                        <Typography.Link
                                            style={{
                                                fontSize: 16,
                                                color: Color.Gray50,
                                            }}
                                            href={c.url}
                                            target="_blank"
                                        >
                                            보기
                                        </Typography.Link>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    )
                })}
            </Row> */}
        </Modal>
    )
}