
import { Button, Col, Divider, Form, Row, Spin, Typography } from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Vector } from '../../assets/svg/Index'
import { InputForm } from '../../components/form/InputForm'
import { DomainService } from '../../services/DomainService'
import { Color } from '../../styles/Color'
import { 앱다운로드 } from '../etc/앱다운로드'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'

export const 도메인검색결과: FunctionComponent = () => {
    const location = useLocation()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [domain, setDomain] = useState<string>(location.state?.keyword)
    const [domains, setDomains] = useState<{ [key: string]: number }>()

    const [form] = Form.useForm()

    // const types = [상표검색타입.타입.All, 상표검색타입.타입.상표, 상표검색타입.타입.출원인]

    const getDomains = async (domain: string) => {
        setLoading(true)
        const response = await DomainService.search({ keyword: domain })
        if (response.status === 200) {
            setDomains(response.data)
        }
        setLoading(false)
    }
    useEffect(() => {
        form.setFieldValue('keyword', location.state?.keyword)

        if (location.state?.keyword) {
            getDomains(location.state?.keyword)
        }
    }, [])

    return (
        <Container>
            <Spin spinning={isLoading}>
                <Row className='search-domain-container'>
                    <Col span={24} className='header'>
                        <LayoutContainer>
                            <Col span={24} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                <Row justify={'center'} style={{ marginTop: 60, }}>
                                    <Col span={24} style={{ marginBottom: 30, }}>
                                        <Typography.Text
                                            strong
                                            style={{
                                                fontSize: 28,
                                                color: Color.Gray10,
                                            }}
                                        >
                                            {'등록하실 도메인을 검색해 주세요!'}
                                        </Typography.Text>
                                    </Col>
                                    <Col span={24}>
                                        <Row justify={'center'}>
                                            <Col span={12}>
                                                <Typography.Text
                                                    style={{
                                                        position: 'absolute',
                                                        top: 12,
                                                        left: 20,
                                                        zIndex: 10,
                                                        fontSize: 18,
                                                    }}
                                                >
                                                    WWW.
                                                </Typography.Text>
                                                <Form form={form}>
                                                    <InputForm
                                                        name='keyword'
                                                        placeholder='도메인을 입력하세요'
                                                        style={{
                                                            height: 50,
                                                            borderRadius: 30,
                                                            fontSize: 16,
                                                            paddingLeft: 90,
                                                        }}
                                                        layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                                                        onPressEnter={() => {
                                                            const keyword = form.getFieldValue('keyword')
                                                            if (!keyword || keyword.trim().length <= 0) {
                                                                alert('도메인을 입력하세요')
                                                                return
                                                            }

                                                            setDomain(keyword)
                                                            getDomains(keyword)
                                                        }}
                                                    />
                                                </Form>
                                                <Typography.Link onClick={() => {
                                                    const keyword = form.getFieldValue('keyword')
                                                    if (!keyword || keyword.trim().length <= 0) {
                                                        alert('도메인을 입력하세요')
                                                        return
                                                    }

                                                    setDomain(keyword)
                                                    getDomains(keyword)
                                                }}>
                                                    <img
                                                        src={Vector.icon.search.gray} width={30}
                                                        style={{
                                                            position: 'absolute',
                                                            top: 10,
                                                            right: 20,
                                                            zIndex: 10,
                                                        }}
                                                        alt='검색'
                                                    />
                                                </Typography.Link>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </LayoutContainer>
                    </Col>

                    {(domains && domains[domain] === 2) && (
                        <Col span={24} style={{ marginTop: 80, marginBottom: 30, }}>
                            <LayoutContainer>
                                <Col span={24}>
                                    <Typography.Text
                                        style={{ fontSize: 24 }}
                                    >
                                        <Typography.Text
                                            strong
                                            style={{ fontSize: 24 }}
                                        >
                                            {`${domain}`}
                                        </Typography.Text>
                                        {'은(는) '}
                                        <Typography.Text
                                            strong
                                            style={{
                                                fontSize: 24,
                                                color: Color.Blue50
                                            }}
                                        >
                                            {`이미 등록`}
                                        </Typography.Text>
                                        {'된 도메인 입니다.'}
                                    </Typography.Text>
                                </Col>
                                <Col
                                    span={24}
                                    style={{
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        padding: '16px 12px',
                                        marginTop: 20,
                                        borderRadius: 8,
                                        borderColor: Color.Gray50,
                                    }}
                                >
                                    <Row justify={'space-between'}>
                                        <Col span={12}>
                                            <Typography.Text
                                                strong
                                                style={{
                                                    fontSize: 18,
                                                    color: Color.Gray50
                                                }}
                                            >
                                                {`${domain}`}
                                            </Typography.Text>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'end' }}>
                                            <Typography.Text
                                                style={{
                                                    fontSize: 18,
                                                    color: Color.Gray50
                                                }}
                                            >
                                                {`등록불가`}
                                            </Typography.Text>

                                        </Col>
                                    </Row>
                                </Col>
                            </LayoutContainer>
                        </Col>
                    )}
                    {domains && (
                        <Col span={24}>
                            <LayoutContainer>
                                <Divider />
                                <Col span={24} style={{ marginBottom: 20 }}>
                                    <Typography.Text
                                        strong
                                        style={{ fontSize: 24 }}
                                    >
                                        {`이런 도메인은 어떠세요?`}
                                    </Typography.Text>
                                </Col>
                                <Col
                                    span={24}
                                >
                                    <Row>
                                        {Object
                                            .keys(domains)
                                            .filter(d => domains[d] === 1 && !d.endsWith('or.kr'))
                                            .map((d) => {
                                                return (
                                                    <Col
                                                        span={24}
                                                        style={{
                                                            borderWidth: 1,
                                                            borderStyle: 'solid',
                                                            padding: '16px 12px',
                                                            marginTop: 20,
                                                            borderRadius: 8,
                                                            borderColor: Color.Gray50,
                                                        }}
                                                    >
                                                        <Row justify={'space-between'}>
                                                            <Col span={12} style={{ alignSelf: 'center' }}>
                                                                <Typography.Text
                                                                    strong
                                                                    style={{
                                                                        fontSize: 18,
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => {
                                                                        window.open(`https://kr.godaddy.com/domainsearch/find?domainToCheck=${d}`, '_blank')
                                                                    }}
                                                                >
                                                                    {`${d}`}
                                                                </Typography.Text>
                                                            </Col>
                                                            <Col span={12} style={{ textAlign: 'end' }}>
                                                                <Button
                                                                    className='primary small'
                                                                    onClick={() => {
                                                                        window.open(`https://kr.godaddy.com/domainsearch/find?domainToCheck=${d}`, '_blank')
                                                                    }}
                                                                >
                                                                    <Typography.Text
                                                                        style={{
                                                                            fontSize: 16,
                                                                            color: Color.Gray10
                                                                        }}
                                                                    >
                                                                        {`바로가기`}
                                                                    </Typography.Text>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )
                                            })}
                                    </Row>
                                </Col>
                            </LayoutContainer>
                        </Col>
                    )}
                    {domains && (
                        <Col span={24}>
                            <LayoutContainer>
                                <Divider />
                                <Col span={24} style={{ marginBottom: 20 }}>
                                    <Typography.Text
                                        strong
                                        style={{ fontSize: 24 }}
                                    >
                                        {`등록이 불가능한 도메인입니다`}
                                    </Typography.Text>
                                </Col>
                                <Col
                                    span={24}
                                >
                                    <Row>
                                        {Object
                                            .keys(domains)
                                            .filter(d => domains[d] === 2 && !d.endsWith('or.kr'))
                                            .map((d) => {
                                                return (
                                                    <Col
                                                        span={24}
                                                        style={{
                                                            borderWidth: 1,
                                                            borderStyle: 'solid',
                                                            padding: '16px 12px',
                                                            marginTop: 20,
                                                            borderRadius: 8,
                                                            borderColor: Color.Gray50,
                                                        }}
                                                    >
                                                        <Row justify={'space-between'}>
                                                            <Col span={12}>
                                                                <Typography.Text
                                                                    strong
                                                                    style={{
                                                                        fontSize: 18,
                                                                        color: Color.Gray50
                                                                    }}
                                                                >
                                                                    {`${d}`}
                                                                </Typography.Text>
                                                            </Col>
                                                            <Col span={12} style={{ textAlign: 'end' }}>
                                                                <Typography.Text
                                                                    style={{
                                                                        fontSize: 18,
                                                                        color: Color.Gray50
                                                                    }}
                                                                >
                                                                    {`등록불가`}
                                                                </Typography.Text>

                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )
                                            })}
                                    </Row>
                                </Col>
                            </LayoutContainer>
                        </Col>
                    )}
                    <Col
                        span={24}
                        style={{
                            marginTop: 80,
                            backgroundColor: Color.Blue100,
                            paddingTop: 50,
                            paddingBottom: 50,
                            textAlign: 'center',
                        }}>
                        <앱다운로드 />
                    </Col>
                </Row>
            </Spin>
        </Container>
    )
}