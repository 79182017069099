import { Button, Col, Row, Space, Typography } from 'antd'
import { FunctionComponent } from 'react'
import { AssetImage } from '../../assets/image/Index'
import { Color } from '../../styles/Color'
import { MenuType } from '../../types/MenuType'
import { Container } from '../layout/Container'
import { LayoutContainer } from '../layout/LayoutContainer'
import { useNavigate } from 'react-router-dom'

export const 서비스안내: FunctionComponent = () => {
    const navigate = useNavigate()

    return (
        <Container menu={MenuType.서비스안내}>
            <Row className='service-container'>
                <Col span={24} className='header'>
                    <LayoutContainer>
                        <Col span={12} style={{ alignSelf: 'center', marginTop: 100, }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 36, fontWeight: 700, color: Color.Gray10, }}>{`상표의 모든 것!`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 36, fontWeight: 700, color: Color.Gray10, }}>{`간편하고 빠르게 해결해요`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Gray10, }}>{`나혼자상표의 서비스를 소개합니다.`}</Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} style={{ marginTop: 40, alignSelf: 'end', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.service.main}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24}>
                    <LayoutContainer style={{ justifyContent: 'space-between', marginTop: 60, }}>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Blue50 }}>{`상표`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 20, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`실시간 등록 가능성 확인을`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`통해 상표 출원까지!`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, }}>{`인공지능을 통한 상표 검색과 등록 가능성 판단을`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 20, }}>{`통해 사용자의 상표 출원 등록률을 높일 수 있어요.`}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.service.first}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ marginTop: 120, }}>
                    <LayoutContainer style={{ justifyContent: 'space-between' }}>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.service.second}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Blue50 }}>{`상표`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 20, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`직접 출원 서비스부터`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`전문가 매칭 서비스까지`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, }}>{`개인이 직접 출원하는 방법을 제공하고,직접`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 20, }}>{`출원이 어렵다면 전문가의 도움을 받을 수 있어요.`}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    </LayoutContainer>
                </Col>
                {/* <Col span={24}>
                    <LayoutContainer style={{ justifyContent: 'space-between', marginTop: 60, }}>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Blue50 }}>{`상표`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 20, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`AI 기반 최적 상품류`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`및 지정상품 범위 제공`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, }}>{`상표권 검색 시, 상표권의 범위를 분석하여 최적 `}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 20, }}>{`상품류 및 최적 지정상품을 제공받을 수 있어요.`}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.service.third}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                    </LayoutContainer>
                </Col> */}
                <Col span={24} style={{ marginTop: 120, }}>
                    <LayoutContainer style={{ justifyContent: 'space-between' }}>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <Row>
                                <Col span={24}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, fontWeight: 700, color: Color.Blue50 }}>{`도메인`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 20, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`상표와 관련된`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 32, fontWeight: 700, }}>{`도메인 검색 서비스 기능`}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 40, }}>
                                    <Space size={0} direction='vertical'>
                                        <Typography.Text style={{ fontSize: 20, }}>{`도메인 등록 등록 가능 여부를 검색하여`}</Typography.Text>
                                        <Typography.Text style={{ fontSize: 20, }}>{`도메인과 확장자를 구매하여 등록할 수 있습니다.`}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={10} style={{ alignSelf: 'center', }}>
                            <img
                                width={'100%'}
                                src={AssetImage.background.service.forth}
                                alt='상표출원을 전문가가 도와드려요' />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col span={24} style={{ marginTop: 160, backgroundColor: Color.Blue100, paddingTop: 20, }}>
                    <LayoutContainer style={{ justifyContent: 'center', }}>
                        <Col span={16} style={{ textAlign: 'center' }}>
                            <Space size={30}>
                                <img src={AssetImage.background.company.people} alt='people' width={'370px'} />˝
                                <Space direction='vertical'>
                                    <Typography.Text style={{ fontSize: 30, fontWeight: 700, color: Color.Gray10, }}>{`출원하려는 상표를`}</Typography.Text>
                                    <Typography.Text style={{ fontSize: 30, fontWeight: 700, color: Color.Gray10, }}>{`등록해 보세요.`}</Typography.Text>
                                    <Button
                                        style={{
                                            marginTop: 20,
                                            borderRadius: 20,
                                            paddingLeft: 30,
                                            paddingRight: 30,
                                        }}
                                        size='large'
                                        onClick={() => {
                                            navigate('/create/trademarks')
                                        }}
                                    >
                                        {`상표 등록하러 가기 >`}
                                    </Button>
                                </Space>
                            </Space>
                        </Col>
                    </LayoutContainer>
                </Col>
            </Row>
        </Container>
    )
}