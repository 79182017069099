import { GoogleOAuthProvider } from '@react-oauth/google'
import 'antd/dist/reset.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Home } from './pages/Home'
import { KakaoOAuth } from './pages/account/KakaoOAuth'
import { NaverOAuth } from './pages/account/NaverOAuth'
import { 로그인 } from './pages/account/로그인'
import { 특허출원방법PDF } from './pages/etc/특허출원방법PDF'
import { 상표출원진행현황 } from './pages/order/상표출원진행현황'
import { Qna } from './pages/qna/Qna'
import { PrivacyTerm } from './pages/term/PrivacyTerm'
import { RefundTerm } from './pages/term/RefundTerm'
import { ServiceTerm } from './pages/term/ServiceTerm'
import { 도메인검색결과 } from './pages/도메인/도메인검색결과'
import { 상표검색 } from './pages/상표검색/상표검색'
import { 상표검색결과 } from './pages/상표검색/상표검색결과'
import { PaymentFail } from './pages/상표등록/fallback/PaymentFail'
import { PaymentSuccess } from './pages/상표등록/fallback/PaymentSuccess'
import { 상표결제 } from './pages/상표등록/상표결제'
import { 상표등록 } from './pages/상표등록/상표등록'
import { 직접출원 } from './pages/상표등록/직접출원'
import { 출원가능성 } from './pages/상표등록/출원가능성'
import { 서비스안내 } from './pages/서비스안내/서비스안내'
import { 절차안내 } from './pages/절차안내/절차안내'
import { 회사소개 } from './pages/회사소개/회사소개'
import './styles/index.scss'

const App = () => {
    const isProduction = process.env.NODE_ENV === 'production'
    const googleKey = isProduction ? '1028684085840-jm4k0atfmlavl58sm9aerpp6s7nna9bn.apps.googleusercontent.com' : '1028684085840-mkjtr2751jkgluvso6orulv53r11o7e9.apps.googleusercontent.com'

    console.log('isProduction', isProduction)

    return (
        <GoogleOAuthProvider clientId={googleKey}>
            <BrowserRouter>
                <Routes>
                    <Route path="/company" element={<회사소개 />} />
                    <Route path="/service" element={<서비스안내 />} />
                    <Route path="/search" element={<상표검색 />} />
                    <Route path="/process" element={<절차안내 />} />

                    <Route path="/create/trademarks" element={<상표등록 />} />
                    <Route path="/trademark-able" element={<출원가능성 />} />

                    <Route path="/search/trademarks" element={<상표검색결과 />} />
                    <Route path="/direct/trademarks" element={<직접출원 />} />
                    <Route path="/payment/trademarks" element={<상표결제 />} />
                    <Route path="/payment/trademarks/:id" element={<상표결제 />} />

                    <Route path="/payment/success/:id" element={<PaymentSuccess />} />
                    <Route path="/payment/fail/:id" element={<PaymentFail />} />

                    <Route path="/search/domain" element={<도메인검색결과 />} />

                    <Route path="/qna" element={<Qna />} />

                    <Route path="/user/trademark-process" element={<상표출원진행현황 />} />

                    <Route path="/login" element={<로그인 />} />
                    <Route path="/kakao/oauth" element={<KakaoOAuth />} />
                    <Route path="/naver/oauth" element={<NaverOAuth />} />

                    <Route path="/views/privacy-term" element={<PrivacyTerm />} />
                    <Route path="/views/service-term" element={<ServiceTerm />} />
                    <Route path="/views/refund-term" element={<RefundTerm />} />

                    <Route path="/views/license" element={<특허출원방법PDF />} />

                    <Route path='*' element={<Home />} />
                </Routes>
            </BrowserRouter>
        </GoogleOAuthProvider>
    )
}

export default App