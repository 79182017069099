import {
    ExclamationCircleOutlined
} from '@ant-design/icons'
import { Col, Divider, Row, Space, Switch, Tooltip, Typography } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { Vector } from "../../assets/svg/Index"
import { Color } from "../../styles/Color"
import { 출원방법타입 } from "../../types/출원방법타입"
import DateUtility from "../../utils/DateUtility"
import NumberUtility from "../../utils/NumberUtility"

interface IProps {
    type: 출원방법타입.출원방법
    code: string
    description?: string
    isActivated: boolean
    isSwitchActivated: boolean
    onPress: () => void
    onPressSwitch: () => void
}

export const 상표결제Item = (props: IProps) => {
    const {
        type,
        code,
        description = '',
        isActivated,
        isSwitchActivated,
        onPress,
        onPressSwitch,
    } = props

    const [items] = useState<Array<{ type: 출원방법타입.출원방법, price: number, display: string }>>([
        {
            type: 출원방법타입.출원방법.직접출원,
            price: 0,
            display: '직접 출원',
        },
        {
            type: 출원방법타입.출원방법.바로출원,
            price: 18000,
            display: '바로 출원',
        },
        {
            type: 출원방법타입.출원방법.간편출원,
            price: 48000,
            display: '간편 출원',
        },
        {
            type: 출원방법타입.출원방법.심화출원,
            price: 88000,
            display: '심화 출원',
        },
    ])

    const paymentTrademarkTypes: Array<출원방법타입.출원방법> = [
        출원방법타입.출원방법.바로출원,
        출원방법타입.출원방법.간편출원,
        출원방법타입.출원방법.심화출원
    ]

    const 관납료 = () => {
        // return Math.round(isSwitchActivated ? 160000 + 46000 : 46000)
        return 0
    }

    const 수수료 = () => {
        let price = 18000

        switch (type) {
            case 출원방법타입.출원방법.간편출원:
                price = 48000
                break
            case 출원방법타입.출원방법.심화출원:
                price = 88000
                break
        }

        return Math.round(isSwitchActivated ? 145000 + price : price)
    }

    const date = (title: string, date: string) => {
        return (
            <Row style={{ marginBottom: 10, }}>
                <Col span={24} style={{ textAlign: 'end' }}>
                    <Space size={20}>
                        <Typography.Text
                            style={{
                                fontSize: 18,
                            }}
                        >
                            {`${title}: `}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontSize: 18,
                            }}
                        >
                            {date}
                        </Typography.Text>

                    </Space>
                </Col>
            </Row>
        )
    }

    return (
        <Col
            span={24}
            style={{
                borderWidth: 1,
                padding: '20px 12px',
                borderStyle: 'solid',
                borderRadius: 8,
                borderColor: isActivated ? Color.Blue50 : Color.Gray50,
            }}
        >
            <Typography.Link
                onClick={onPress}
            >
                <Row gutter={[12, 12]} justify={'space-around'} style={{ marginBottom: 10, }}>
                    <Col span={24}>
                        <Space>
                            <img src={isActivated ? Vector.check.blue : Vector.check.gray} alt={'icon'} />
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 22,
                                    color: isActivated ? Color.Blue60 : Color.Gray80
                                }}
                            >
                                {`${code}류`}
                            </Typography.Text>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 18,
                                color: isActivated ? Color.Blue60 : Color.Gray80
                            }}
                        >
                            {description}
                        </Typography.Text>
                    </Col>
                </Row>
            </Typography.Link>
            <Row gutter={[16, 16]} justify={'center'} style={{ marginBottom: 16, }}>
                {paymentTrademarkTypes.map((t: 출원방법타입.출원방법) => {
                    const item = items.find(i => i.type === t)
                    const isTypeActivated = isActivated && t === type
                    
                    return (
                        <Col
                            key={t}
                            span={8}
                        >
                            <div
                                style={{
                                    borderWidth: 1,
                                    padding: '20px 12px',
                                    borderStyle: 'solid',
                                    borderRadius: 8,
                                    borderColor: isTypeActivated ? Color.Blue50 : Color.Gray50,
                                    backgroundColor: isTypeActivated ? Color.Blue20 : Color.Gray10,
                                }}
                            >
                                <Space
                                    direction="vertical"
                                    style={{
                                        width: '100%',
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 18,
                                            color: isTypeActivated ? Color.Blue50 : Color.Gray80,
                                        }}
                                    >
                                        {item?.display}
                                    </Typography.Text>
                                </Space>
                            </div>
                        </Col>
                    )
                })}
            </Row>
            <Row justify={'end'}>
                <Col span={14}>
                    {date('상표 출원 예정일', DateUtility.date_format())}
                </Col>
                <Col span={14}>
                    {date('1차 심사 결과 통지 예상일', DateUtility.date_format(dayjs().add(isSwitchActivated ? 4 : 12, 'month')))}
                </Col>
                <Col span={14}>
                    {date('최종 등록 예상일', `${DateUtility.date_format(dayjs().add(isSwitchActivated ? 6 : 14, 'month'), 'YYYY-MM')}월~${DateUtility.date_format(dayjs().add(isSwitchActivated ? 12 : 20, 'month'), 'YYYY-MM')}월`)}
                </Col>
                <Col span={14} style={{ textAlign: 'end' }}>
                    <Space size={20}>
                        <Typography.Text
                            style={{
                                fontSize: 18,
                                textAlign: 'start',
                            }}
                        >
                            <Space>
                                {`심사 기간 단축`}
                                <Tooltip title='우선심사 제도를 신청합니다. 우선심사 신청 시 12개월에서 약 2~4개월로 단축됩니다.'>
                                    <ExclamationCircleOutlined />
                                </Tooltip>
                            </Space>
                        </Typography.Text>
                        <Switch
                            disabled={!isActivated}
                            checked={isSwitchActivated}
                            onChange={onPressSwitch} />
                    </Space>
                </Col>
                <Divider />
            </Row>
            <Row justify={'end'}>
                <Col span={24} style={{ textAlign: 'end' }}>
                    <Space size={1} direction='vertical'>
                        {/* <Typography.Text
                            style={{
                                fontSize: 18,
                                textAlign: 'start',
                                color: isActivated ? Color.Blue50 : Color.Gray80,
                            }}
                        >
                            {`특허청 관납료(VAT 제외):  ${NumberUtility.comma(관납료())}`}
                        </Typography.Text> */}
                        <Typography.Text
                            style={{
                                fontSize: 18,
                                textAlign: 'start',
                                color: isActivated ? Color.Blue50 : Color.Gray80,
                            }}
                        >
                            {`나혼자상표 수수료:  ${NumberUtility.comma(수수료())}`}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontSize: 18,
                                textAlign: 'start',
                                color: isActivated ? Color.Blue50 : Color.Gray80,
                            }}
                        >
                            {`VAT:  ${NumberUtility.comma(Math.round(수수료() * 0.1))}`}
                        </Typography.Text>
                    </Space>
                </Col>
                <Col span={24} style={{ textAlign: 'end', marginTop: 20 }}>
                    <Typography.Text
                        strong
                        style={{
                            fontSize: 24,
                            textAlign: 'start',
                            color: isActivated ? Color.Blue50 : Color.Gray80,
                        }}
                    >
                        {`${NumberUtility.comma(Math.round(수수료() * 1.1) + 관납료())} 원`}
                    </Typography.Text>
                </Col>
            </Row>
        </Col>
    )
}