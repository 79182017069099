import { Button, Col, Form, Modal, Row, Space, Typography } from "antd"
import { FunctionComponent, useEffect, useState } from "react"
import { Vector } from "../../assets/svg/Index"
import { TrademarkModel } from "../../models/TrademarkModel"
import { Color } from "../../styles/Color"
import { InputForm } from "../form/InputForm"

interface IProps {
    open: boolean
    담당자?: TrademarkModel.ITrademarkModel담당자Model
    출원인?: TrademarkModel.ITrademarkModel출원인Model
    onPress: (출원인: TrademarkModel.ITrademarkModel출원인Model,) => void
    onClosed: () => void
}

export const 출원자정보입력Modal: FunctionComponent<IProps> = (props) => {
    const {
        open,
        담당자,
        출원인,
        onPress,
        onClosed,
    } = props

    const [담당자정보동기화, set담당자정보동기화] = useState<boolean>(false)

    const [form] = Form.useForm()

    const Footer = () => {
        return (
            <Row>
                <Col span={24} style={{ textAlign: 'end' }}>
                    <Space>
                        <Button
                            type="default"
                            onClick={onClosed}
                        >
                            취소
                        </Button>
                        <Button
                            className="primary small"
                            onClick={() => {
                                form.validateFields()
                                    .then(() => {
                                        onPress(form.getFieldsValue())
                                    })
                                    .catch((e) => {
                                        alert('필수 정보를 입력해주세요')
                                        return
                                    })
                            }}
                        >
                            등록
                        </Button>
                    </Space>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        if (출원인) {
            form.setFieldValue('koreanFullName', 출원인?.koreanFullName)
            form.setFieldValue('englishFullName', 출원인?.englishFullName)
            form.setFieldValue('email', 출원인?.email)
            form.setFieldValue('phoneNumber', 출원인?.phoneNumber)
            form.setFieldValue('applicantCode', 출원인?.applicantCode)
        }
    }, [출원인])

    return (
        <Modal
            open={open}
            destroyOnClose={true}
            title={'출원인 정보'}
            onCancel={() => { onClosed() }}
            footer={<Footer />}
        >
            <Form form={form}>
                <Row gutter={[12, 6]} style={{ marginTop: 40, }}>
                    <Col span={24} style={{ marginBottom: 20, }}>
                        <Typography.Link
                            onClick={() => {
                                if (담당자정보동기화) {
                                    form.setFieldValue('koreanFullName', '')
                                    form.setFieldValue('email', '')
                                    form.setFieldValue('phoneNumber', '')
                                } else {
                                    form.setFieldValue('koreanFullName', 담당자?.fullName)
                                    form.setFieldValue('email', 담당자?.email)
                                    form.setFieldValue('phoneNumber', 담당자?.phoneNumber)
                                }
                                set담당자정보동기화(!담당자정보동기화)
                            }}
                        >
                            <Row justify={'space-between'}>
                                <Col>
                                    <Typography.Text style={{ fontSize: 16, color: Color.Gray80 }}>
                                        {'담당자와 동일'}
                                    </Typography.Text>
                                </Col>
                                <Col>
                                    <img src={담당자정보동기화 ? Vector.check.blue : Vector.check.gray} alt="담당자 정보 동기화"/>
                                </Col>
                            </Row>
                        </Typography.Link>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    {`[필수] 출원인 이름(한글)`}
                                </Typography.Text>
                            </Col>
                            <InputForm
                                name='koreanFullName'
                                placeholder='출원인 이름을 입력하세요'
                                rules={[{ required: true, message: "출원인 이름을 입력하세요" }]}
                                style={{
                                    borderRadius: 8,
                                    fontSize: 16,
                                }}
                                layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            />
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    {`[필수] 출원인 이름(영문)`}
                                </Typography.Text>
                            </Col>
                            <InputForm
                                name='englishFullName'
                                placeholder='출원인 이름을 입력하세요'
                                rules={[{ required: true, message: "출원인 이름을 입력하세요" }]}
                                style={{
                                    borderRadius: 8,
                                    fontSize: 16,
                                }}
                                layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            />
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        <Typography.Text
                                            style={{
                                                fontSize: 14,
                                            }}
                                        >
                                            {`[필수] 특허고객번호`}
                                        </Typography.Text>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <Typography.Link
                                            href="https://blob.markerslab.app/download/%ED%8A%B9%ED%97%88%EA%B3%A0%EA%B0%9D%EB%B2%88%ED%98%B8%EB%B0%9C%EA%B8%89%EB%B0%A9%EB%B2%95.pdf"
                                            target="_blank"
                                        >
                                            <Space size={1}>
                                                <Typography.Text
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {`특허고객번호 생성방법`}
                                                </Typography.Text>
                                                <img 
                                                    src={Vector.icon.alert.gray} 
                                                    style={{ marginBottom: 3 }}
                                                    alt="특허고객번호 생성방법"
                                                />
                                            </Space>
                                        </Typography.Link>
                                    </Col>
                                </Row>
                            </Col>
                            <InputForm
                                name='applicantCode'
                                placeholder='특허고객번호를 입력하세요'
                                rules={[
                                    { required: true, message: "특허고객번호를 입력하세요" },
                                ]}
                                style={{
                                    borderRadius: 8,
                                    fontSize: 16,
                                }}
                                layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            />
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    {`[필수] 출원인 이메일`}
                                </Typography.Text>
                            </Col>
                            <InputForm
                                name='email'
                                placeholder='출원인 이메일을 입력하세요'
                                rules={[
                                    { required: true, message: "출원인 이메일을 입력하세요" },
                                    {
                                        pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                        message: '이메일을 정확하게 입력해주세요'
                                    },
                                ]}
                                style={{
                                    borderRadius: 8,
                                    fontSize: 16,
                                }}
                                layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            />
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[0, 10]}>
                            <Col span={24}>
                                <Typography.Text
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    {`[필수] 출원인 휴대전화`}
                                </Typography.Text>
                            </Col>
                            <InputForm
                                name='phoneNumber'
                                placeholder='출원인 휴대전화를 입력하세요'
                                rules={[
                                    { required: true, message: "출원인 휴대전화를 입력하세요" },
                                    // {
                                    //     pattern: /^[0-9]*$/,
                                    //     message: "출원인 휴대전화를 숫자만 입력하세요"
                                    // }
                                ]}
                                style={{
                                    borderRadius: 8,
                                    fontSize: 16,
                                }}
                                layout={{ lg: { span: 24 }, sm: { span: 24 }, xs: { span: 24 } }}
                            />
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}