import { Button, Col, Divider, Modal, Row, Space, Typography } from "antd"
import React, { FunctionComponent, useState } from "react"
import { Color } from "../../styles/Color"
import { Vector } from "../../assets/svg/Index"
import { SocialType } from "../../types/SocialType"

interface IProps {
    type: SocialType
    onCompleted: (type: SocialType) => void
    onClosed: () => void
}

export const 이용약관동의Modal: FunctionComponent<IProps> = (props) => {
    const { type, onCompleted, onClosed } = props

    const [ids, setIds] = useState<Array<number>>([])

    const contents = [
        { id: 1, title: '(필수) 만 14세 이상입니다' },
        { id: 2, title: '(필수) 서비스 이용약관 동의', url: '/views/service-term' },
        { id: 3, title: '(필수) 개인정보 처리방침 동의', url: '/views/privacy-term' },
    ]
    const Footer = () => {
        return (
            <React.Fragment>
                <Button type="default" onClick={() => { onClosed() }}>
                    취소
                </Button>
                <Button 
                    disabled={ids.length !== contents.length}
                    type="primary" 
                    onClick={() => { onCompleted(type) }}>
                    동의
                </Button>
            </React.Fragment>
        )
    }

    return (
        <Modal
            title="이용약관동의"
            open={type != null}
            destroyOnClose={true}
            onCancel={() => { onClosed() }}
            footer={<Footer />}
        >
            <Row style={{ marginTop: 30, }}>
                <Col span={24}>
                    <Typography.Link
                        onClick={() => {
                            if (ids.length === contents.length) {
                                setIds([])
                            } else {
                                setIds(contents.map(c => c.id))
                            }
                        }}
                    >
                        <Space>
                            <img src={ids.length === contents.length ? Vector.check.blue : Vector.check.gray} alt="동의" />
                            <Typography.Text style={{ fontSize: 16, color: Color.Gray80 }}>
                                전체동의
                            </Typography.Text>
                        </Space>
                    </Typography.Link>
                </Col>
            </Row>
            <Divider />
            <Row>
                {contents.map((c) => {
                    const isActivated = ids.includes(c.id)
                    return (
                        <Col key={c.id} span={24}>
                            <Row justify={'space-between'}>
                                <Col>
                                    <Typography.Link
                                        onClick={() => {
                                            if(isActivated) {
                                                setIds(ids.filter( i => i !== c.id))
                                            } else {
                                                setIds([ ...ids, c.id ])
                                            }
                                        }}
                                    >
                                        <Space>
                                            <img src={isActivated ? Vector.check.blue : Vector.check.gray} alt="동의" />
                                            <Typography.Text style={{ fontSize: 16, color: Color.Gray80 }}>
                                                {c.title}
                                            </Typography.Text>
                                        </Space>
                                    </Typography.Link>
                                </Col>
                                {c.url && (
                                    <Col>
                                        <Typography.Link
                                            style={{
                                                fontSize: 16,
                                                color: Color.Gray50,
                                            }}
                                            href={c.url}
                                            target="_blank"
                                        >
                                            보기
                                        </Typography.Link>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    )
                })}
            </Row>
        </Modal>
    )
}