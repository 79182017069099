import { Col, Row, Table, Typography } from "antd"
import { useEffect, useState } from "react"
import { OrderModel } from "../../models/OrderModel"
import { PaginationModel } from "../../models/PaginationModel"
import { OrderService } from "../../services/OrderService"
import { Color } from "../../styles/Color"
import { MenuType } from "../../types/MenuType"
import { NavigationType } from '../../types/NavigationType'
import { 출원방법타입 } from '../../types/출원방법타입'
import { 출원진행타입 } from "../../types/출원진행타입"
import DateUtility from "../../utils/DateUtility"
import { 앱다운로드 } from "../etc/앱다운로드"
import { Container } from "../layout/Container"
import { LayoutContainer } from "../layout/LayoutContainer"
import { 상표출원진행현황상세 } from "./상표출원진행현황상세"

export const 상표출원진행현황 = () => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const [orders, setOrders] = useState<PaginationModel.IPaginationModel<OrderModel.IOrderModel>>()

    const getOrders = async () => {
        setLoading(true)
        const response = await OrderService.search()
        if (response.status === 200) {
            setOrders(response.data)
        }
        setLoading(false)
    }
    useEffect(() => {
        getOrders()
    }, [])

    return (
        <Container type={NavigationType.White} menu={MenuType.로그인}>
            <Row className='process-container' justify={'center'} align={'middle'}>
                <Col span={24} style={{ textAlign: 'center', paddingTop: 50, }}>
                    <div>
                        <Typography.Text
                            style={{
                                fontSize: 24,
                                fontWeight: 700,
                            }}
                        >
                            {`상표 출원 진행 현황`}
                        </Typography.Text>
                    </div>
                    <LayoutContainer style={{ marginTop: 50, }}>
                        <Col span={24} style={{ alignSelf: 'center', }}>
                            <Table
                                rowKey={record => record.id}
                                loading={isLoading}
                                pagination={false}
                                dataSource={orders ? orders.items : []}
                                columns={[
                                    {
                                        title: "상표명칭",
                                        dataIndex: "koreanTrademarkName",
                                        key: "koreanTrademarkName",
                                        align: 'center',
                                    },
                                    {
                                        title: "출원방법",
                                        dataIndex: "commissionType",
                                        key: "commissionType",
                                        render: (commissionType: 출원방법타입.출원방법) => {
                                            return (
                                                출원방법타입.displayName(commissionType)
                                            )
                                        },
                                        align: 'center',
                                    },
                                    {
                                        title: "출원상태",
                                        dataIndex: "currentStep",
                                        key: "currentStep",
                                        render: (type: 출원진행타입.출원진행) => {
                                            return (
                                                출원진행타입.displayName(type)
                                            )
                                        },
                                        align: 'center',
                                    },
                                    {
                                        title: "접수일자",
                                        dataIndex: "kstCreatedDate",
                                        key: "kstCreatedDate",
                                        render: (date: string) => {
                                            return (
                                                DateUtility.date_format(date)
                                            )
                                        },
                                        align: 'center',
                                    },
                                ]}
                                expandable={{
                                    expandIcon: () => { return <></> },
                                    expandRowByClick: true,
                                    expandedRowRender: (record) => {
                                        return (
                                            <상표출원진행현황상세 id={record.id} subId={record.subIds} />
                                        )
                                    }
                                }}
                            />
                        </Col>
                    </LayoutContainer>
                </Col>
                <Col
                    span={24}
                    style={{
                        marginTop: 160,
                        backgroundColor: Color.Blue80,
                        paddingTop: 50,
                        paddingBottom: 50,
                        textAlign: 'center',
                    }}>
                    <앱다운로드 />
                </Col>
            </Row>
        </Container>
    )
}