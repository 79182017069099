import { Button, Card, Col, Divider, Modal, Row, Space, Tabs, Typography } from "antd"
import { uniq } from "lodash-es"
import { FunctionComponent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CategoryModel } from "../../models/CategoryModel"
import { 출원방법타입 } from "../../types/출원방법타입"
import NumberUtility from "../../utils/NumberUtility"

interface IProps {
    open: boolean
    name: string
    alphabet?: string
    categories: Array<CategoryModel.ITrademarkCategoryFlattenModel>
    secondaryCodes?: Array<string>
    ratio: number
    onSelected: (type: 출원방법타입.출원방법, codes: Array<string>) => void
    onClosed: () => void
}

export const 출원방법선택Modal: FunctionComponent<IProps> = (props) => {
    const {
        open,
        name,
        alphabet,
        categories,
        secondaryCodes,
        ratio,
        onSelected,
        onClosed
    } = props

    const navigate = useNavigate()
    
    const [codes, setCodes] = useState<Array<string>>([])
    const [items] = useState<Array<{ type: 출원방법타입.출원방법, ratio: number, price: number, display: string, descriptions: Array<string> }>>([
        {
            type: 출원방법타입.출원방법.직접출원,
            price: 0,
            display: '직접 출원',
            ratio: 70,
            descriptions: [
                'AI 기반 상표 등록 가능성 제공',
                'AI 기반 상품류/지정 상품 제공',
                '출원 프로세스 튜토리얼 제공',
            ]
        },
        {
            type: 출원방법타입.출원방법.바로출원,
            price: 18000,
            display: '바로 출원',
            ratio: 70,
            descriptions: [
                'AI 기반 상표 등록 가능성 제공',
                'AI 기반 상품류/지정 상품 제공',
                '사무소 출원',
                '특허청 관납료, VAT 별도',
            ]
        },
        {
            type: 출원방법타입.출원방법.간편출원,
            price: 48000,
            display: '간편 출원',
            ratio: 0,
            descriptions: [
                '변리사 선임 상담',
                '등록 가능성 조사',
                '상표 등록 자문',
                '특허청 관납료, VAT 별도',
            ]
        },
        {
            type: 출원방법타입.출원방법.심화출원,
            price: 88000,
            display: '심화 출원',
            ratio: 0,
            descriptions: [
                '변리사 선임 상담',
                '등록 가능성 조사',
                '상표 등록 자문',
                '상표 권리범위 자문',
                '사무소 출원',
                '특허청 관납료, VAT 별도',
            ]
        },
    ])
    const [type, setType] = useState<string>(
        `${items.filter(v => v.ratio <= ratio)[0].type}`
    )

    useEffect(() => {
        if (secondaryCodes) {
            setCodes([...uniq([...categories.map(c => c.codes[0]!), ...secondaryCodes])])
        } else {
            setCodes([...uniq([...categories.map(c => c.codes[0]!)])])
        }
    }, [secondaryCodes])

    const Footer = () => {
        return (
            <Row gutter={[16, 0]} style={{ paddingTop: 40, }} justify={'center'}>
                <Col span={12}>
                    <Button
                        block
                        className="primary"
                        disabled={codes.length <= 0 || !type}
                        onClick={() => {
                            switch (type) {
                                case '1':
                                    navigate('/direct/trademarks')
                                    break
                                case '2':
                                    onSelected(출원방법타입.출원방법.바로출원, codes)
                                    break
                                case '3':
                                    onSelected(출원방법타입.출원방법.간편출원, codes)
                                    break
                                case '4':
                                    onSelected(출원방법타입.출원방법.심화출원, codes)
                                    break
                            }
                        }}
                    >
                        출원하기
                    </Button>
                </Col>
            </Row>
        )
    }

    return (
        <Modal
            width={'80%'}
            open={open}
            className="gateway"
            destroyOnClose={true}
            footer={<Footer />}
            bodyStyle={{
                maxWidth: 600,
            }}
            onCancel={() => { onClosed() }}
        >
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Space direction="vertical">
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 20,
                            }}
                        >
                            {`${name} ${alphabet ? `(${alphabet})` : ''}`}
                        </Typography.Text>
                        <Space>
                            {codes.map((c, idx: number) => {
                                return (
                                    <Typography.Text
                                        key={idx}
                                        style={{
                                            fontSize: 18,
                                        }}
                                    >
                                        {`${c}류${idx === (codes.length - 1) ? '' : ', '}`}
                                    </Typography.Text>
                                )
                            })}
                        </Space>
                    </Space>
                    <Divider />
                </Col>
                <Col span={24}>
                    <Typography.Text
                        strong
                        style={{
                            fontSize: 22,
                        }}
                    >
                        {`출원 방법 선택`}
                    </Typography.Text>
                    <Row>
                        <Col span={24}>
                            <Tabs
                                centered
                                // type="card"
                                // activeKey={NaverSearchAdTabType.EXTENSION}
                                onChange={(key: string) => {
                                    setType(key)
                                }}
                                items={
                                    items
                                        .filter(v => v.ratio <= ratio)
                                        .map((t) => {
                                            return {
                                                label: t.display,
                                                key: `${t.type}`,
                                                children: (
                                                    <Card>
                                                        <Row gutter={[16, 16]}>
                                                            <Col
                                                                span={24}
                                                                style={{
                                                                    borderBottom: '1px solid #eee',
                                                                    paddingBottom: 16,
                                                                }}
                                                            >
                                                                <Space size={6} direction="vertical" style={{ width: '100%', textAlign: 'center' }}>
                                                                    <Typography.Text
                                                                        style={{
                                                                            fontSize: 22,
                                                                            fontWeight: 700,
                                                                        }}
                                                                    >
                                                                        {t.display}
                                                                    </Typography.Text>
                                                                    <Typography.Text
                                                                        style={{
                                                                            fontSize: 16,
                                                                            fontWeight: 700,
                                                                        }}
                                                                    >
                                                                        {`${NumberUtility.comma(t.price)}원`}
                                                                    </Typography.Text>
                                                                </Space>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Space direction="vertical" style={{ width: '100%', textAlign: 'center' }}>
                                                                    {t.descriptions.map((r) => {
                                                                        return (
                                                                            <Typography.Text
                                                                                key={r}
                                                                                style={{
                                                                                    fontSize: 16,
                                                                                }}
                                                                            >
                                                                                {r}
                                                                            </Typography.Text>
                                                                        )
                                                                    })}
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                )
                                            };
                                        })}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}