import { Button, Col, Modal, Row, Space, Typography } from "antd"
import { FunctionComponent } from "react"

interface IProps {
    open: boolean
    categories: Array<string>
    onConfirm: () => void
    onClosed: () => void
}

export const 상품류확인Modal: FunctionComponent<IProps> = (props) => {
    const { open, categories, onConfirm, onClosed } = props

    const Footer = () => {
        return (
            <Row justify={'center'} style={{ marginTop: 40 }}>
                <Col span={16}>
                    <Button
                        block
                        className="primary"
                        onClick={onConfirm}
                    >
                        검색 결과 보러 가기
                    </Button>
                </Col>
            </Row>
        )
    }

    return (
        <Modal
            width={'60%'}
            open={open}
            destroyOnClose={true}
            onCancel={() => { onClosed() }}
            footer={<Footer />}
        >
            <Row
                gutter={[24, 24]}
                className='create-trademark-container'
            >
                <Col span={24}>
                    <Space direction="vertical">
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 24,
                            }}
                        >
                            {`선택한 상품류를`}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                fontSize: 24,
                            }}
                        >
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 24,
                                }}
                            >
                                {`확인`}
                            </Typography.Text>
                            <Typography.Text
                                style={{
                                    fontSize: 24,
                                }}
                            >
                                {`해 주세요.`}
                            </Typography.Text>
                        </Typography.Text>
                    </Space>
                </Col>
                <Col span={24}>
                    <Space size={20} wrap>
                        {categories.map((item) => {
                            return (
                                <Button
                                    key={item}
                                    className={`activated`}
                                    style={{
                                        borderRadius: 30,
                                    }}
                                    onClick={() => {
                                    }}
                                >
                                    <Typography.Text
                                        style={{
                                            fontSize: 16,
                                        }}
                                    >
                                        {item}
                                    </Typography.Text>
                                </Button>
                            )
                        })}
                    </Space>
                </Col>
            </Row>
        </Modal>
    )
}